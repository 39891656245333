import React, {Fragment} from 'react';
import { List, Datagrid, SimpleForm, TextInput, FileInput, FileField, Edit,FormDataConsumer, ArrayInput, SimpleFormIterator, EditButton } from 'react-admin';
import CustomReferenceField from '../component/CustomReferenceField';

export const PersonFileList = (props) => (
    <List {...props}>
        <Datagrid>
            <CustomReferenceField/>
            <FileField source="files" src="url" title="title" />
            <EditButton />
        </Datagrid>
    </List>
);

export const PersonFileEdit = (props) => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <FileInput source="newFile" label="Add new file" accept="">
                <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                <Fragment>
                    {Array.isArray(formData.files) ?
                        <ArrayInput source="files">
                            <SimpleFormIterator disableAdd>
                                <TextInput style={{marginLeft: '1rem'}} label="Filename" source="title" disabled/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    :
                    null}
                </Fragment>}
        </FormDataConsumer>
        </SimpleForm>
    </Edit>
);