// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFile = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {
    if (type === 'CREATE' && resource === 'files') {
        const file = params.data.file;

        return convertFile(file).then(transformedFile => requestHandler(type,resource, {
            ...params,
            data:{
                ...params.data,
                file: transformedFile,
                title: `${params.data.file.title}`
            }
        }))
    }

    if (type === 'CREATE' && resource === 'personFile') {
        const file = params.data.files;
        if(file) {
            return convertFile(file).then(transformedFile => requestHandler(type,resource, {
                ...params,
                data:{
                    ...params.data,
                    file: transformedFile,
                    title: `${params.data.files.title}`
                }
            }))
        }
    }

    if (type === 'UPDATE' && resource === 'personFile') {
        const file = params.data.newFile;
        if (file) {
            return convertFile(file).then(transformedFile => requestHandler(type,resource, {
                ...params,
                data:{
                    ...params.data,
                    file: transformedFile,
                    title: `${params.data.newFile.title}`
                }
            }))
        }
    }

    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);


};

export default addUploadFeature;