import React, {Component, Fragment} from 'react';
import { Create, Edit, SimpleForm, ReferenceInput, SelectInput, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import AttributesInput from '../component/AttributesInput';
import AttributesDescriptionInput from '../component/AttributesDescriptionInput';
import { APIURL } from '../utils/constants';
import MDSpinner from "react-md-spinner";


export default class PersonEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {attributes: undefined, loading: false};
    }

    componentWillMount() {
        this.attributesRequest();
    }

    attributesRequest = () => {
        var request = require('request')
        request({uri:`${APIURL}/attributes`, headers : { "Authorization" : localStorage.getItem('token')}, json: true},(error, response, body) =>{
            this.setState({attributes: body});
        })
    }

    render(){
        const required = (message = 'Required') =>
        value => value ? undefined : message;

        const isCreate = this.props.location.pathname.indexOf('/create') !== -1;
        const Page = isCreate ? Create : Edit;
        let props = this.props;
        if (!isCreate) {
            props = { ...this.props, undoable: false };
        }

        return(
            <div>
                <Page {...props} >
                    <SimpleForm>
                        <ReferenceInput label="Person type" source="typeId" reference="personType">
                            <SelectInput optionText="type" optionValue="id"/>
                        </ReferenceInput>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                        <ArrayInput source="attributes" validate={required()}>
                            <SimpleFormIterator>
                            <AttributesDescriptionInput source="attributeId" attributes={this.state.attributes}/>
                            <FormDataConsumer>
                                    {({
                                        formData,
                                        scopedFormData,
                                        getSource,
                                        ...rest,
                                    }) =>
                                        scopedFormData && scopedFormData.attributeId ? (
                                            <Fragment>
                                                {this.state.attributes ?
                                                    <AttributesInput source={getSource("value")} attributes={this.state.attributes} attributeId={scopedFormData.attributeId}/>
                                                :
                                                    <MDSpinner/>
                                                }
                                                </Fragment>
                                ) : null
                            }
                        </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                        }
                        </FormDataConsumer>
                    </SimpleForm>
                </Page>
            </div>
        );
    }
}