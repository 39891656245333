import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { APIURL } from '../utils/constants';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ChatIcon from '@material-ui/icons/ChatBubble';

import { push as pushAction } from 'react-router-redux'
import { showNotification as showNotificationAction } from 'react-admin'

const style = {
  color: 'green',
  width: 20
}

class SendMessageButton extends Component {
  state = {
    open: false,
    value: null
  };

  handleOpen = () => {
    this.setState({open: true})
  };

  handleClose = () => {
    this.setState({open: false})
  };

  handleClick = () => {
    const { value } = this.state
    const { record, showNotification, toPerson, toStaticSegment } = this.props

      const url = `${APIURL}/${toPerson ? 'person': toStaticSegment ? 'staticSegment' : 'segment'}/message`
      let options = toPerson ? {
        method: 'POST',
        headers: new Headers({'Authorization': localStorage.getItem('token')}),
        body: JSON.stringify({
          value,
          person: record.id
        }),
        // eslint-disable-next-line
      }
      : toStaticSegment ? {
        method: 'POST',
        headers: new Headers({'Authorization': localStorage.getItem('token')}),
        body: JSON.stringify({
          value,
          staticSegment: record.id
        }),
        // eslint-disable-next-line
      }
      : {
        method: 'POST',
        headers: new Headers({'Authorization': localStorage.getItem('token')}),
        body: JSON.stringify({
          value,
          segment: record.id
        }),
        // eslint-disable-next-line
      }


      // eslint-disable-next-line
      fetch(url, options)
        .then(() => {
          this.handleClose()
          showNotification('Message sent')
        })
        .catch(() => {
          this.handleClose()
          showNotification('Error: unable to send message', 'warning')
        })
    }

  render () {
    return (
      <div>
        <Button
          style={style}
          onClick={this.handleOpen}
        ><ChatIcon/></Button>
        <Dialog open={this.state.open}>
          <DialogContent>
            {this.props.toPerson ?
            <h3>Write a message to send to this person through Telegram</h3>
            :
            <h3>Write a message to send to the users who belong to this segment through Telegram</h3>
            }
            <TextField
              autoFocus
              id="name"
              fullWidth
              multiline
              rows="10"
              onChange={(e) => this.setState({value: e.target.value})}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleClick} color="primary">
              Send message
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

SendMessageButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func
}

export default connect(null, {
  push: pushAction,
  showNotification: showNotificationAction
})(SendMessageButton)
