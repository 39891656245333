
import React from 'react';
import { List, Datagrid, TextField, CardActions, CreateButton,  EditButton } from 'react-admin';
import SendMessage from '../component/SendMessage';

const SegmentActions = () => (
    <CardActions>
        <CreateButton basePath={'segment'}/>
    </CardActions>
);

export const SegmentList = (props) => (
    <List {...props} actions={<SegmentActions/>}>
        <Datagrid>
            <TextField label="Segment" source="description" sortable={false}/>
            <EditButton />
            <SendMessage />
        </Datagrid>
    </List>
);