import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const CustomReferenceField = ({  record = {} }) => (
    <Fragment>
        <a style={{textDecoration: 'none'}} href={`${window.location.origin}/#/person/${record.personId}`}>
            <div style={{color: '#3f51b5', fontSize: '.9rem'}}>
                {record['email']}
            </div>
        </a>
    </Fragment>
);

CustomReferenceField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};

CustomReferenceField.defaultProps = {
    addLabel: true,
};

export default CustomReferenceField;