import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const AttributesDescAndValueField = ({  record = {} }) => (
    <Fragment>
        {
        <ul style={{width: '600px'}}>
            {record.attributes.map(item => (
                <li key={item.name}>
                <div style={{display: 'flex',justifyContent: 'space-between', width: '80%'}}>
                    <div style={{fontWeight: '600'}}>
                        {`${item.description}: `}
                    </div>
                    <div>
                        {item.value}
                    </div>
                </div>
                </li>
            ))}
        </ul>
        }
    </Fragment>
);

AttributesDescAndValueField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};

AttributesDescAndValueField.defaultProps = {
    addLabel: true,
};

export default AttributesDescAndValueField;