import React from 'react';
import { List, Datagrid, TextField, Create, SimpleForm, TextInput, Edit, EditButton } from 'react-admin';

const required = (message = 'Required') =>
    value => value ? undefined : message;

export const PersonTypeList = (props) => (
    <List {...props} >
        <Datagrid>
            <TextField source="type" sortable={false}/>
            <EditButton />
        </Datagrid>
    </List>
);

export const PersonTypeEdit = (props) => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextInput source="type" validate={required()} />
        </SimpleForm>
    </Edit>
);

export const PersonTypeCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="type" validate={required()} />
        </SimpleForm>
    </Create>
);