import React, { Component } from 'react';
import { Create, SimpleForm, AutocompleteInput, FileInput, FileField } from 'react-admin';
import { APIURL } from '../utils/constants';
import MDSpinner from "react-md-spinner";


export default class PersonFileCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {attributes: undefined, loading: false};
    }

    componentWillMount() {
        this.attributesRequest();
    }

    attributesRequest = () => {
        var request = require('request')
        request({uri:`${APIURL}/person/email`, headers : { "Authorization" : localStorage.getItem('token')}, json: true},(error, response, body) =>{
            this.setState({attributes: body});
        })
    }

    render(){

        let choices = []
        if (this.state.attributes && this.state.attributes.length > 0) {
            choices = this.state.attributes.map((value) => {
                return {id: value.name, name: value.name}
            })
        }

        return(
            <div>
                <Create {...this.props} >
                    <SimpleForm>
                        {this.state.attributes ?
                            <AutocompleteInput source="email" choices={choices} />
                        :
                            <MDSpinner/>
                        }
                        <FileInput source="files" label="Related files" accept="">
                            <FileField source="src" title="title" />
                        </FileInput>
                    </SimpleForm>
                </Create>
            </div>
        );
    }
}