import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const AttributesField = ({  record = {} }) => (
    <Fragment>
        {
        record['fieldType'] === "enum" &&
        record.values.map((arr) => {
            return <li key={arr.name}>{arr.name}</li>
        })
        }
    </Fragment>
);

AttributesField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};

AttributesField.defaultProps = {
    addLabel: true,
};

export default AttributesField;