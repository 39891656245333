import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import SendMessage from '../component/SendMessage';
import CustomPeopleList from '../component/CustomPeopleList';

export const StaticSegmentList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="description" sortable={false}/>
            <CustomPeopleList />
            <SendMessage toStaticSegment/>
        </Datagrid>
    </List>
);