import React from 'react';
import { List, Datagrid, TextField, Create, SimpleForm, FileInput, FileField } from 'react-admin';


export const FilesList = (props) => (
<List {...props}>
        <Datagrid>
            <TextField source="title" sortable={false}/>
        </Datagrid>
    </List>
);

export const FilesCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <FileInput source="file" label="files">
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Create>
);