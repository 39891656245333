import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import authProvider  from '../utils/authProvider';
import { PersonList } from './person';
import { PersonTypeList, PersonTypeCreate, PersonTypeEdit } from './personType';
import { AttributesList, AttributesCreate, AttributesEdit } from './attributes';
import { FilesList, FilesCreate } from './files';
import  addUploadFeature  from '../utils/addUploadFeature';
import { APIURL } from '../utils/constants';
import { SegmentList } from './segment';
import SegmentEdit from '../component/SegmentEdit';
import PersonEdit from '../component/PersonEdit';
import { PersonFileList, PersonFileEdit } from './personFile';
import PersonFileCreate from '../component/PersonFileCreate';
import { StaticSegmentList } from './staticSegment';
import { UsersList, UsersCreate, UsersEdit } from './users';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = jsonServerProvider(APIURL,httpClient);
const uploadCapableDataProvider = addUploadFeature(dataProvider);
const App = () => (
  <Admin dataProvider={uploadCapableDataProvider} authProvider={authProvider}>

    {permissions => [
      <Resource name="person" list={PersonList} edit={PersonEdit} create={PersonEdit}/>,
      <Resource options={{ label: 'Person Type' }} name="personType" list={PersonTypeList} create={PersonTypeCreate} edit={PersonTypeEdit} />,
      <Resource name="attributes" list={AttributesList} create={AttributesCreate} edit={AttributesEdit} pagination={null} />,
      <Resource options={{ label: 'Import People' }} name="files" list={FilesList} create={FilesCreate}/>,
      <Resource name="segment" list={SegmentList} create={SegmentEdit} edit={SegmentEdit}/>,
      <Resource options={{ label: 'Static Segments' }} name="staticSegment" list={StaticSegmentList} create={SegmentEdit}/>,
      <Resource options={{ label: 'Files' }} name="personFile" list={PersonFileList} create={PersonFileCreate} edit={PersonFileEdit}/>,
      permissions === 'admin'
        ? <Resource name="users" list={UsersList} create={UsersCreate} edit={UsersEdit} />
        : null,
    ]}
  </Admin>
);

export default App;