import React, {Component, Fragment} from 'react';
import { TextInput,  Create, Edit, SimpleForm, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import AttributesInput from '../component/AttributesInput';
import AttributesDescriptionInput from '../component/AttributesDescriptionInput';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import keyBy from 'lodash/keyBy';
import OperandInput from './OperandInput';
import { APIURL } from '../utils/constants';
import MDSpinner from "react-md-spinner";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



const Map = withScriptjs(withGoogleMap((props) => {
    const google = window.google;
    const markers = props.mks;
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(marker => bounds.extend(marker));

    return (
        <GoogleMap
            defaultZoom={2}
            defaultCenter={{ lat: 0, lng: 0 }}
            ref={ref => { ref && ref.fitBounds(bounds) }}
        >
            {props.mks.map(mk => <Marker key={mk.key} position={mk} />)}
        </GoogleMap>
    )
}));


export default class SegmentEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {numberOfPeople: '-', loading: false, markers: [], attributes: undefined};
    }

    componentWillMount() {
        this.attributesRequest();
    }

    attributesRequest = () => {
        var request = require('request')
        request({uri:`${APIURL}/attributes`, headers : { "Authorization" : localStorage.getItem('token')}, json: true},(error, response, body) =>{
            console.log('BODY',body);
            this.setState({attributes: body});
        })
    }

    aside = (markers = []) => (
        <div style={{ width: 500, margin: '10px', textAlign: 'center' }}>
            <span>
                Number of people matching current segment rules:
            </span>
            <p style={{ fontSize: '2em', fontWeight: 'bold'}}>
              {this.state.loading ?
                <MDSpinner />
              :
                this.state.numberOfPeople
              }
            </p>
            {markers.length ? (
                <Map
                    mks={markers}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBqiHv3lXRL_C2EpQGJhpDLLztjXm4GFwY&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            ) : null}
        </div>
    );

    numberOfPeopleRequest = (formData) => {
        var request = require('request')
        request({method:'POST',uri:`${APIURL}/segment/result`, headers : { "Authorization" : localStorage.getItem('token')}, json: true, body:formData},(error, response, body) =>{
            console.log('MARKERS', body.result);
            const markers = body.result ? body.result
                .filter(person => person.latitude && person.longitude)
                .map(person => {
                    const attributes = keyBy(person.attributes, 'description');

                    const firstName = attributes['First Name'] || {};
                    const lastName = attributes['Surname'] || {};
                    const phone = attributes['Mobile Number'] || {};
                    const prefix = attributes['Country Prefix'] || {};

                    return {
                        ...person,
                        name: `${firstName.value || ''} ${lastName.value || ''}`,
                        phone: `${prefix.value || ''} ${phone.value || ''}`,
                        key: person.id,
                        type: person.type,
                        lat: person.latitude,
                        lng: person.longitude
                    };
                }) : null;
            this.setState({numberOfPeople: body.result ? body.result.length : 0, markers, loading: false});
        })
    }

    debouncedRequest = debounce(this.numberOfPeopleRequest, 1200);

    conditionalUpdateState = (formData) =>{
        if (!isEqual(this.state.formData, formData)){
            this.setState({formData: formData});
            this.setState({loading: true});
            this.debouncedRequest(formData);
        }
    }

    render(){
        const required = (message = 'Required') =>
        value => value ? undefined : message;

        const isCreate = this.props.location.pathname.indexOf('/create') !== -1;
        const Page = isCreate ? Create : Edit;
        let props = this.props;
        if (!isCreate) {
            props = { ...this.props, undoable: false };
        }

        const { markers } = this.state;

        return(
            <div>
                <Page {...props} aside={this.aside(markers)}>
                    <SimpleForm>
                        <TextInput source="description" validate={required()} />
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                        <ArrayInput source="rules">
                            <SimpleFormIterator>
                                <AttributesDescriptionInput source="attributeId" attributes={this.state.attributes}/>
                                <FormDataConsumer>
                                        {({
                                            formData,
                                            scopedFormData,
                                            getSource,
                                            ...rest,
                                        }) =>
                                            scopedFormData && scopedFormData.attributeId ? (
                                                <Fragment>
                                                {this.state.attributes ?
                                                    <Fragment>
                                                        <OperandInput source={getSource("operandValue")} attributes={this.state.attributes} attributeId={scopedFormData.attributeId}/>
                                                        <br/>
                                                        <AttributesInput source={getSource("value")} attributes={this.state.attributes} attributeId={scopedFormData.attributeId}/>
                                                        {this.conditionalUpdateState(formData)}
                                                    </Fragment>
                                                :
                                                    <MDSpinner/>
                                                }
                                                </Fragment>
                                    ) : null
                                    }
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                        }
                        </FormDataConsumer>
                    </SimpleForm>
                </Page>
                { markers.length ? <div>
                    <Paper style={{width: '100%', marginTop: '20px'}}>
                        <Table style={{width: '100%'}}>
                            <TableHead>
                            <TableRow>
                                <TableCell>Matched person</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Phone Number</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {markers.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>{row.phone}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </div> : null }
            </div>
        );
    }
}