import React from 'react';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { addField } from 'react-admin';




class AttributesDescriptionInput extends React.Component {
    render() {
        const { input, meta: { touched, error }, attributes} = this.props;
        if (!attributes){
            return <span>Loading...</span>
        } else {
            return (
                <SelectField
                    errorText={touched && error}
                    {...input}
                >
                    {attributes.map((element) => {
                        return <MenuItem key={element.id} value={element.id}>{element.description}</MenuItem>
                    })
                    }
                </SelectField>
            );
        }
    }
}
export default addField(AttributesDescriptionInput); // decorate with redux-form's <Field>