import React from 'react';
import { List, Datagrid, TextField, Create, CardActions, CreateButton,  SimpleForm, TextInput, RadioButtonGroupInput,FormDataConsumer, ArrayInput, SimpleFormIterator,EditButton, Edit } from 'react-admin';
import AttributesField from '../component/AttributesField';

const required = (message = 'Required') =>
    value => value ? undefined : message;


    const AttActions = () => (
        <CardActions>
            <CreateButton basePath={'attributes'}/>
        </CardActions>
    );

export const AttributesList = (props) => (
    <List {...props} exporter={null} actions={<AttActions/>}>
        <Datagrid>
            <TextField source="description" sortable={false}/>
            <TextField source="fieldType" sortable={false}/>
            <AttributesField label="Attributes" sortable={false}/>
            <EditButton />
        </Datagrid>
    </List>
);

export const AttributesEdit = (props) => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextInput source="description" validate={required()} />
            <RadioButtonGroupInput source="fieldType" choices={[
                { id: 'enum', name: 'enum' },
                { id: 'number', name: 'number' },
                { id: 'string', name: 'string' },
            ]} />
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                     formData.fieldType === "enum" &&
                        <ArrayInput source="values">
                            <SimpleFormIterator defaultValue={""}>
                                <TextInput source="name" label="Value" />
                            </SimpleFormIterator>
                        </ArrayInput>
                }
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

export const AttributesCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="description" validate={required()} />
            <RadioButtonGroupInput source="fieldType" choices={[
                { id: 'enum', name: 'enum' },
                { id: 'number', name: 'number' },
                { id: 'string', name: 'string' },
            ]} />
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                     formData.fieldType === "enum" &&
                        <ArrayInput source="values">
                            <SimpleFormIterator defaultValue={""}>
                                <TextInput source="name" label="Value" />
                            </SimpleFormIterator>
                        </ArrayInput>
                }
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);