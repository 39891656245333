import React from 'react';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { addField } from 'react-admin';
import find from 'lodash/find';




class AttributesInput extends React.Component {

    render() {
        const { input, meta: { touched, error }, attributeId, attributes} = this.props;

        const attribute = find(attributes,(element) => {
            return attributeId === element.id
        })

        if (!attributes || !attribute){
            return <span>Loading...</span>
        } else if (attribute.fieldType === 'string'){
            return (
                <TextField
                    {...input}
                />
            )
        } else if (attribute.fieldType === 'number'){
            return (
                <TextField
                    type="number"
                    errorText={touched && error}
                    {...input}
                />
            )
        } else {
            return (
                <SelectField
                    errorText={touched && error}
                    {...input}
                >
                    {attribute.values.map((value) => {
                        return (
                            <MenuItem value={value.name}>
                                {value.name}
                            </MenuItem>
                        )
                    })}
                </SelectField>
            );
        }
    }
}
export default addField(AttributesInput); // decorate with redux-form's <Field>