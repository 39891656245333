
import React from 'react';
import { List, Datagrid, ChipField, CardActions, CreateButton, ReferenceField, EditButton } from 'react-admin';
import AttributesDescAndValueField from '../component/AttributesDescAndValueField';
import SendMessage from '../component/SendMessage';

const PeopleActions = () => (
    <CardActions>
        <CreateButton basePath={'person'}/>
    </CardActions>
);

export const PersonList = (props) => (
    <List {...props} actions={<PeopleActions/>}>
        <Datagrid>
            <ReferenceField label="Type" source="typeId" reference="personType" sortable={false}>
                <ChipField source="type" />
            </ReferenceField>
                <AttributesDescAndValueField label="Attributes" source="attributes" sortable={false}/>
            <EditButton />
            <SendMessage toPerson={true}/>
        </Datagrid>
    </List>
);