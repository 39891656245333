import React from 'react';
import { List, Datagrid, TextField, Create, SimpleForm, TextInput, Edit, EditButton, SelectInput } from 'react-admin';

const required = (message = 'Required') =>
    value => value ? undefined : message;

export const UsersList = (props) => (
    <List {...props} >
        <Datagrid>
            <TextField source="name" sortable={false}/>
            <TextField source="email" sortable={false}/>
            <TextField source="role" sortable={false}/>
            <EditButton />
        </Datagrid>
    </List>
);

export const UsersEdit = (props) => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
            <TextInput source="email" type="email" validate={required()} />
            <TextInput source="password" type="password" validate={required()} />
            <SelectInput source="role" choices={[
                { id: 'admin', name: 'admin' },
                { id: 'user', name: 'user' },
            ]} />
        </SimpleForm>
    </Edit>
);

export const UsersCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
            <TextInput source="email" type="email" validate={required()} />
            <TextInput source="password" type="password" validate={required()} />
            <SelectInput source="role" choices={[
                { id: 'admin', name: 'admin' },
                { id: 'user', name: 'user' },
            ]} />
        </SimpleForm>
    </Create>
);