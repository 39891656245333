import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { APIURL } from '../utils/constants';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MDSpinner from "react-md-spinner";

import { push as pushAction } from 'react-router-redux'
import { showNotification as showNotificationAction } from 'react-admin'

const style = {
  color: '#3f51b5',
  width: 120
}

function Transition(props) {
    return <Slide direction="up" {...props} />;
  }

class CustomPeopleList extends Component {
  state = {
    open: false,
    value: null,
    people: [],
    loading: true
  };

    peopleRequest = () => {
        var request = require('request')
        request({method:'POST',uri:`${APIURL}/staticSegment/people`, headers : { "Authorization" : localStorage.getItem('token')}, json: true, body:this.props.record},(error, response, body) =>{
            if (error === null) {
                console.log('PEOPLE', body)
                this.setState({people: body ? body : []});
            }
            this.setState({loading: false})
        })
    }

  handleOpen = () => {
    this.setState({open: true})
    this.peopleRequest();
  };

  handleClose = () => {
    this.setState({open: false})
  };

  render () {
    return (
      <div>
        <Button
          style={style}
          onClick={this.handleOpen}
        ><PersonIcon style={{marginRight: '.6rem'}}/> People</Button>
        <Dialog
            fullScreen
            open={this.state.open}
            onClose={this.handleClose}
            TransitionComponent={Transition}
        >
          <AppBar style={{position: 'relative'}} >
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <div style={{flex: '1', flexGrow: '1', textAlign: 'right'}}>
                People details
              </div>
            </Toolbar>
          </AppBar>
          <List>
              {this.state.loading === false ?
                <Fragment>
                    {this.state.people.map((element) => {
                        return (
                        <Fragment key={element._id}>
                            <ListItem button style={{display: 'flex',justifyContent: 'center'}}>
                            {
                            <div style={{width: '60%', display: 'flex', justifyContent: 'center'}}>
                                <div style={{width: '20%'}}>
                                    <span>{element.type}</span>
                                </div>
                                <div style={{width: '80%'}}>
                                    <ul>
                                        {element.attributes.map(item => (
                                            <li key={item.attributeId}>
                                            <div style={{display: 'flex',justifyContent: 'space-between'}}>
                                                <div style={{fontWeight: '600'}}>
                                                    {`${item.description}: `}
                                                </div>
                                                <div>
                                                    {item.value}
                                                </div>
                                            </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            }
                            </ListItem>
                            <Divider />
                        </Fragment>
                        )
                    })}
                </Fragment>
              :
                <ListItem>
                    <MDSpinner/>
                </ListItem>
              }
          </List>
        </Dialog>
      </div>
    )
  }
}

CustomPeopleList.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func
}

export default connect(null, {
  push: pushAction,
  showNotification: showNotificationAction
})(CustomPeopleList)
